<template>
  <ion-card class="mx-0 mt-0 px-3 py-3 shadow border-rad-0">
    <ion-card-content class="pa-0">
      <div class="text-center">
        <ion-text class="fs-2">{{ $t('page_taking_longer') }}</ion-text>
        <div class="mt-4 d-flex column-center">
          <ion-label class="fw-600 mb-2">{{ $t('pull_to_refresh') }}</ion-label>
          <ion-icon color="primary fs-2rem" :icon="arrowDownOutline"></ion-icon>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>
<script>
import { arrowDownOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    return {
      arrowDownOutline
    };
  }
});
</script>

<style lang="scss" scoped>
.border-rad-0 {
  border-radius: 0 !important;
}
.column-center {
  flex-direction: column;
  align-items: center;
}
.fs-2rem {
  font-size: 2rem;
}
</style>
