<template>
  <ion-page>
    <ion-header>
      <toolbar
        @reRenderToolbar="refetchCartItems()"
        :selectedCompany="selectedCompany"
        :isOnScrollTop="isOnScrollTop"
      />
    </ion-header>
    <ion-content ref="contentRef" :scroll-events="true" @ionScroll="handleScroll($event)">
      <ion-refresher slot="fixed" @ionRefresh="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <PullRefresh v-if="!isOnline"></PullRefresh>
      <div v-if="isOnline">
        <div v-if="isCartEmpty" class="cart-empty">
          <ion-img class="cart-empty-img" :src="imageDefault"> </ion-img>
          <div class="d-flex justify-center flex-column mt-2" align="center">
            <div>
              <ion-label color="grey" class="fs-3 fw-bold">{{ $t('sorry') }}</ion-label>
            </div>
            <div>
              <ion-label color="grey" class="msg-empty">{{ $t('message_cart_empty') }}</ion-label>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="groupedCartByTenant" class="block-tenant-list">
            <ion-grid
              class="pa-3 mt-3 block-tenant-item"
              v-for="(tenantItems, index) in groupedCartByTenant"
              :key="index"
            >
              <!-- checkbox all -->
              <ion-row class="pb-3">
                <ion-col size="1" class="d-flex align-self-center">
                  <div
                    class="item-checkbox"
                    :class="disableCheckboxAll && 'disabled-checkbox'"
                    @click="toggleCheckboxAllByTenant(tenantItems[0].tenant_id)"
                  >
                    <ion-icon
                      color="primary"
                      v-if="isCheckAllByTenant(tenantItems[0].tenant_id) === true"
                      :icon="checkbox"
                    ></ion-icon>
                    <ion-icon
                      color="grey2"
                      v-else-if="isCheckAllByTenant(tenantItems[0].tenant_id) === -1"
                      :icon="square"
                    ></ion-icon>
                    <ion-icon color="grey5" v-else :icon="squareOutline"></ion-icon>
                  </div>
                </ion-col>
                <ion-col size="auto" class="title-tenant d-flex align-self-center fw-500">
                  <ion-label class="text-ellipsis">{{ tenantItems[0].tenant_name }} </ion-label>
                </ion-col>
                <ion-col size="1" class="d-flex align-self-center">
                  <ion-icon :icon="chevronForwardOutline" />
                </ion-col>
              </ion-row>

              <!-- list items -->
              <cart-item
                v-for="item in tenantItems"
                :key="item.id"
                :item="item"
                :currencySymbol="currencySymbol"
                @update-quantity-weight="updateQuantityWeightItem"
                @open-modal-delete="openModalDelete"
                @remove-zero-price-item="removeZeroPriceItem"
                @toggle-checkbox="toggleCheckboxItem"
                @uncheck-checkbox="uncheckCheckboxItem"
              />
            </ion-grid>
          </div>
        </div>
      </div>
    </ion-content>

    <ion-footer v-if="!isCartEmpty && isOnline">
      <ion-toolbar>
        <ion-grid>
          <ion-row>
            <ion-col size="6">
              <div>
                <ion-label class="fs-3">{{ $t('total_price') }}</ion-label>
              </div>
              <div class="mt-2">
                <ion-label class="fs-4 fw-bold" color="primary">
                  {{ totalPriceSelected }}
                </ion-label>
              </div>
            </ion-col>
            <ion-col size="6" class="d-flex align-center justify-end">
              <ion-button
                @click="orderNow"
                :disabled="totalItemSelected === 0"
                class="text-capitalize"
                color="primary"
                >{{ $t('order_now') }} ({{ totalItemSelected }})</ion-button
              >
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-footer>
    <ion-modal :is-open="isOpenCheckoutRef" css-class="modal-checkout" @didDismiss="setOpenCheckout(false)">
      <modal-checkout
        :cartData="cart"
        :itemsSelected="itemsSelected"
        @close-modal="setOpenCheckout(false)"
        @place-order="placeOrder"
        @refresh-cart="refreshCartItems()"
      ></modal-checkout>
    </ion-modal>
    <ion-modal
      mode="ios"
      backdrop-dismiss="false"
      :is-open="isOpenPriceUpdated"
      css-class="modal-price-updated"
      @didDismiss="setOpenModalPriceUpdate(false)"
    >
      <price-update-alert @close-modal="setOpenModalPriceUpdate(false)"></price-update-alert>
    </ion-modal>
    <ion-modal
      mode="ios"
      backdrop-dismiss="false"
      :is-open="isOpenItemsNoAvailable"
      css-class="modal-price-updated"
      @didDismiss="closeNoAvailableItems"
    >
      <alert-info-modal
        :message="$t('some_item_no_available')"
        :buttonText="$t('return_to_cart')"
        @close-modal="closeNoAvailableItems"
      ></alert-info-modal>
    </ion-modal>
  </ion-page>
</template>
<script>
import { apolloClient } from '@/main';
import { emptyCartImgage } from '@/modules/b2b/constants';
import { getCustomerDetailBuyer, getLatestPrice } from '@/modules/b2b/services/graphql';
import { displayPrice, randomId } from '@/modules/b2b/services/libs/helper';
import { ACTIONS as ACTIONS_CART, ACTIONS as ACTIONS_UPDATE_PRICE } from '@/modules/b2b/store/cart/actions';
import { isQuantityAMultipleOfIncrement, useLoading } from '@/modules/shared/utils/';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import PullRefresh from '@/modules/shared/views/refreshPage/PullRefresh.vue';
import { useAlert } from '@/usecases/global';
import { priceFormatter } from '@/utils/';
import { getPlatforms, isPlatform, modalController } from '@ionic/vue';
import { arrowDownOutline, checkbox, chevronForwardOutline, square, squareOutline } from 'ionicons/icons';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import { defineAsyncComponent, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import { createNamespacedHelpers, useStore } from 'vuex';
import PriceUpdateAlert from './components/PriceUpdateAlert.vue';
import Toolbar from './components/Toolbar.vue';
const { mapGetters: mapGettersCart, mapActions: mapActionsCart } = createNamespacedHelpers('b2b/cart');
const { mapActions: mapActionsPriceUpdated } = createNamespacedHelpers('b2b/cart');
const { mapGetters: mapGettersCustomer } = createNamespacedHelpers('b2b/customer');
export default defineComponent({
  name: 'Cart',
  props: {
    timeout: { type: Number, default: 1000 }
  },
  components: {
    Toolbar,
    // components
    CartItem: defineAsyncComponent(() => import('./components/CartItem.vue')),
    ModalCheckout: defineAsyncComponent(() => import('./components/ModalCheckout/Index.vue')),
    AlertInfoModal: defineAsyncComponent(() => import('@/modules/shared/components/AlertInfoModal.vue')),
    PriceUpdateAlert,
    PullRefresh
  },
  setup() {
    const { createAlertTwoAction, createAlert } = useAlert();
    const store = useStore();
    const router = useRouter();
    const imageDefault = ref(emptyCartImgage);
    const user = ref(null);
    const selectedCompany = ref(null);
    const allTenantSelected = ref([]);
    const uniqueId = () => randomId();
    const groupedCartByTenant = ref([]);
    const isOpenCheckoutRef = ref(false);
    const setOpenCheckout = (state) => (isOpenCheckoutRef.value = state);
    const deleteItem = ref(null);
    const localCart = ref(null);
    const checkLatestPrice = ref([]);
    const latesCartItemPrice = ref(null);
    const currentCartItemsPrice = ref([]);
    const latestPrice = ref([]);
    const oosItemSelected = ref([]);
    const latesCartItem = ref([]);
    const itemsUpdate = ref({});
    const { showLoading } = useLoading();
    const latestPriceError = ref(false);
    const isOpenItemsNoAvailable = ref(false);
    const isOnScrollTop = ref(false);

    const setOpenItemsNoAvailable = (state) => (isOpenItemsNoAvailable.value = state);
    return {
      router,
      allTenantSelected,
      priceFormatter,
      user,
      showLoading,
      uniqueId,
      groupedCartByTenant,
      selectedCompany,
      deleteItem,
      itemsUpdate,
      isOpenCheckoutRef,
      setOpenCheckout,
      checkLatestPrice,
      latesCartItemPrice,
      currentCartItemsPrice,
      latestPrice,
      oosItemSelected,
      latesCartItem,
      isOpenItemsNoAvailable,
      setOpenItemsNoAvailable,
      // image
      imageDefault,
      chevronForwardOutline,
      checkbox,
      squareOutline,
      square,
      // controller
      modalController,
      localCart,
      latestPriceError,
      isOnline: ref(true),
      arrowDownOutline,
      store,
      isOnScrollTop,
      isOpenPriceUpdated: ref(false),
      createAlertTwoAction,
      createAlert,
      handleScroll: debounce((e) => {
        const onScrollHeightValue = e.detail.scrollTop;
        if (onScrollHeightValue > 10) isOnScrollTop.value = true;
        if (onScrollHeightValue === 0) isOnScrollTop.value = false;
      }, 200)
    };
  },

  watch: {
    selectedCustomer: {
      async handler(newCompany, oldCompany) {
        if (oldCompany && newCompany && newCompany.id !== oldCompany.id) {
          // get the new cart items with new customer id
          await this[ACTIONS_CART.GET_CART_ITEMS]({
            userId: this.user.id,
            customerId: newCompany?.id
          });

          // refresh cart items
          await this.refetchCartItems();
        }
      },
      deep: true
    },
    cart(value) {
      this.localCart = value;
      this.refetchCartItems();
    },
    latesCartItemPrice() {
      this.latestPrice = this.latesCartItemPrice.map(function (cartItem) {
        return cartItem.price;
      });
    },
    itemsSelected() {
      const checkOosSelectedItem = this.itemsSelected[0]?.map((item) => item.is_out_of_stock);
      this.oosItemSelected = checkOosSelectedItem?.map((index) => index);
    }
  },
  async ionViewWillEnter() {
    this.updateCartToStore();
    if (
      this.isRedirect3DSecure &&
      this.paymentOrderId &&
      (getPlatforms().includes('mobileweb' || 'pwa' || 'desktop') ||
        (!isPlatform('ios') && !isPlatform('android')))
    ) {
      this.router.push(`/b2b/payment-transaction/${this.paymentOrderId}`);
    }
  },
  computed: {
    ...mapGettersCustomer(['selectedCustomer']),
    ...mapGettersCart(['cart', 'isRedirect3DSecure', 'paymentOrderId']),
    currencySymbol() {
      return this.user && this.user.currency_symbol;
    },
    isCartEmpty() {
      return !(this.localCart && this.localCart.items && this.localCart.items.length !== 0);
    },
    isCheckAllByTenant() {
      return (tenantId) => {
        if (!this.allTenantSelected) return false;
        const index = this.allTenantSelected.findIndex((item) => item.tenantId === tenantId);
        if (index === -1) return false;

        if (this.allTenantSelected[index].total === 0) return -1;

        return this.allTenantSelected[index].total === this.allTenantSelected[index].totalSelected;
      };
    },
    totalItemSelected() {
      if (this.isCartEmpty) return 0;

      const items = Object.values(this.groupedCartByTenant).flat();

      return items.reduce((total, current) => {
        if (current.selected && !current.is_out_of_stock && !current.is_unavailable) {
          if (current.is_order_by_weight) {
            total += 1;
          } else {
            total += current.order_qty;
          }
        }
        return total;
      }, 0);
    },
    disableCheckboxAll() {
      const items = Object.values(this.groupedCartByTenant).flat();
      const value = items.reduce((allChecked, current) => {
        if (!this.isItemDisable(current)) {
          allChecked = true;
        }
        return allChecked;
      }, false);
      return !value;
    },
    totalPriceSelected() {
      if (this.isCartEmpty) return 0;

      const items = Object.values(this.groupedCartByTenant).flat();

      const totalPrice = items.reduce((total, current) => {
        if (current.selected && !current.is_out_of_stock && !current.is_unavailable) {
          if (!current.is_order_by_weight) {
            return total + Number(current.order_qty * current.price);
          }
          return (
            total +
            Number(
              (current.price / current.weight) *
                Number(
                  current.total_available_quantity > 0 &&
                    current.order_weight > current.total_available_quantity
                    ? current.total_available_quantity
                    : current.order_weight
                )
            )
          );
        }
        return total;
      }, 0);
      return priceFormatter(this.currencySymbol, displayPrice(totalPrice));
    },
    itemsSelected() {
      const data = Object.values(this.groupedCartByTenant).map((tenantItems) => {
        return tenantItems.filter((item) => item.selected && !item.is_out_of_stock && !item.is_unavailable);
      });
      return data.filter((tenantItems) => tenantItems.length !== 0);
    }
  },

  inject: ['$storage'],

  beforeUpdate() {
    this.getGroupedCartByTenant();
    this.getAllTenantSelected();
  },

  methods: {
    ...mapActionsCart([
      ACTIONS_CART.GET_CART_ITEMS,
      ACTIONS_CART.CHANGE_CART_ITEMS,
      ACTIONS_CART.REMOVE_CART_ITEM,
      ACTIONS_CART.STORE_3D_SECURE_PROCESS
    ]),
    ...mapActionsPriceUpdated([ACTIONS_UPDATE_PRICE.UPDATE_LATEST_PRICE]),
    async handleRefresh(event) {
      await this.detectConnection();
      await this.handleGetCustomerDetail();
      await this.updateCartToStore();
      await this.getGroupedCartByTenant();
      await this.getAllTenantSelected();
      event ? event.target.complete() : ``;
    },
    detectConnection() {
      const connection = navigator.onLine;
      if (connection) {
        this.isOnline = true;
      } else {
        this.isOnline = false;
      }
    },
    async placeOrder() {
      this.setOpenCheckout(false);
    },
    async orderNow() {
      // set the selected items before the cart items updated
      const prevLocalSelectedItems = this.itemsSelected[0]?.map((item) => item);

      //check if the selected cart item oos status have update and then compare it with lates data update
      this.latesCartItem = [];
      await this[ACTIONS_CART.GET_CART_ITEMS]({
        userId: this.user.id,
        customerId: this.selectedCompany.id
      });

      const localCartItems = this.localCart.items;
      let itemSelectedData = this.itemsSelected[0]?.map((item) => item) || [];

      for (let i = 0; i < localCartItems.length; i++) {
        for (let j = 0; j < itemSelectedData.length; j++) {
          if (localCartItems[i].sku_id === itemSelectedData[j].sku_id) {
            this.latesCartItem.push(localCartItems[i].is_out_of_stock);
          }
        }
      }

      const itemsMatch = this.latesCartItem.every((value, index) => value === this.oosItemSelected[index]);
      // check if any items lo longer available
      let hasUnavailableItems = false;
      let hasBlockedCartItems = false;
      // Create a map of latestCartItems for efficient lookup by ID
      const latestCartItemsMap = new Map(localCartItems.map((item) => [item.id, item]));
      // Iterate through prevLocalSelectedItems and compare is_unavailable
      prevLocalSelectedItems.forEach((prevItem) => {
        const latestItem = latestCartItemsMap.get(prevItem.id);
        const itemIsUnavailableOrNoAvailableQty =
          latestItem?.is_unavailable || !latestItem?.total_available_quantity;
        const orderItemQuantity = latestItem.is_order_by_weight
          ? latestItem.order_weight
          : latestItem.order_qty; // get order weight or order quantity
        const quantityNotOrderable = latestItem.total_available_quantity
          ? orderItemQuantity > latestItem.total_available_quantity
          : itemIsUnavailableOrNoAvailableQty; // quantity available is below ordered qty or item is not available
        const blockedCartItems = !latestItem.is_back_order && quantityNotOrderable;
        if (blockedCartItems) {
          this.itemsUpdate = {
            ...latestItem,
            order_weight: latestItem.total_available_quantity
              ? Number(latestItem.total_available_quantity.toFixed(2))
              : latestItem.order_weight,
            order_qty: latestItem.total_available_quantity
              ? Number(latestItem.total_available_quantity)
              : latestItem.order_qty
          };
          hasBlockedCartItems = !latestItem.total_available_quantity;
          return;
        }
        hasUnavailableItems = latestItem?.is_unavailable || hasBlockedCartItems;
      });
      if (itemsMatch) {
        // if selected item oos has no update or same with lates cart items data
        // allow to next process to check lates price update
        await this.getLatestPrice();
        await this.compareCartItemPrice();
        if (this.latestPriceError) {
          return;
        }
        if (hasUnavailableItems || hasBlockedCartItems) {
          // show alert info modal that any items no longer available
          this.setOpenItemsNoAvailable(true);
          this.updateQuantityWeightItem(this.itemsUpdate);
          this.refetchCartItems();
        } else if (JSON.stringify(this.currentCartItemsPrice) !== JSON.stringify(this.latestPrice)) {
          // show price update info if any price update
          this.setOpenModalPriceUpdate(true);
        } else {
          // go to checkout process if there is no price update
          this.setOpenCheckout(true);
        }
      } else {
        // if selected item oos has update or different with lates cart items data
        // reload cart item data
        this.refetchCartItems();
      }
    },
    async showAlertError(customErrorMsg) {
      this.createAlert(
        `${this.$t('errorTitle')}`,
        customErrorMsg || `${this.$t('something_went_wrong_please_try_again')}`,
        () => {
          this.handleGetCustomerDetail();
          this.detectConnection();
        },
        `${this.$t('OK')}`
      );
    },
    async handleGetCustomerDetail() {
      this.selectedCompany = await this.$storage.getSelectedCompany();
      try {
        const { data } = await apolloClient.query({
          query: getCustomerDetailBuyer,
          variables: {
            id: this.selectedCompany.id
          }
        });
        await this.$storage.setSelectedCompany({
          ...this.selectedCompany,
          account_number: data.getCustomerDetailBuyer.account_number
        });
        const updateCompany = await this.$storage.getSelectedCompany();
        this.selectedCompany = updateCompany;
        this.latestPriceError = false;
      } catch (error) {
        console.log(error);
      }
    },
    updateCartToStore() {
      this.showLoading(async () => {
        this.user = await this.$storage.getUser();
        this.selectedCompany = await this.$storage.getSelectedCompany();
        return this[ACTIONS_CART.GET_CART_ITEMS]({
          userId: this.user.id,
          customerId: this.selectedCompany.id
        });
      });
    },
    async openModalDelete(item) {
      await this.createAlertTwoAction(
        `${this.$t('delete_this_item')}`,
        `${this.$t('text_remove_item_in_cart')}`,
        `${this.$t('yes_delete')}`,
        () => this.removeItem(item),
        `${this.$t('cancel')}`,
        `alert-vertical-action`
      );
    },

    removeZeroPriceItem(item) {
      this.removeItem(item);
    },

    async setOpenModalPriceUpdate(params) {
      const cartItemsId = this.localCart.items.map(function (cartItem) {
        return cartItem.id;
      });
      this.latestPrice = this.latesCartItemPrice.map(function (cartItem) {
        return cartItem.price;
      });
      let cartItemUpdatePrice = cartItemsId.map((cartItemsId, index_value) => {
        return {
          cartItemId: cartItemsId,
          price: this.latestPrice[index_value]
        };
      });
      if (!params) {
        await this[ACTIONS_UPDATE_PRICE.UPDATE_LATEST_PRICE]({
          payload: cartItemUpdatePrice
        });
        this.updateCartToStore();
      }
      this.isOpenPriceUpdated = params;
    },

    isItemDisable(item) {
      if (item.is_order_by_weight) {
        return (
          (!item.is_back_order &&
            item.total_available_quantity !== null &&
            item.order_weight > item.total_available_quantity) ||
          item.is_out_of_stock ||
          !item.order_weight ||
          item.is_unavailable
        );
      } else {
        return (
          !isQuantityAMultipleOfIncrement({
            quantity: item.order_qty,
            increment: item.increment_qty
          }) ||
          (!item.is_back_order &&
            item.total_available_quantity !== null &&
            item.order_qty > item.total_available_quantity) ||
          item.is_out_of_stock ||
          !item.order_qty ||
          item.is_unavailable
        );
      }
    },

    async removeItem(item) {
      // find the item in local cart
      this.localCart.items = this.localCart.items.filter((el) => el.id !== item.id);
      await this.showLoading(() => {
        return Promise.allSettled([
          this[ACTIONS_CART.REMOVE_CART_ITEM]({
            id: item.id
          }),
          this.refetchCartItems()
        ]);
      });
    },

    async updateQuantityWeightItem(item) {
      this.localCart.items.forEach((o) => {
        if (o.id === item.id) {
          o.order_qty = !item.is_order_by_weight
            ? item.order_qty
            : item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
            ? item.total_available_quantity
            : item.order_weight;
          o.order_weight =
            item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
              ? item.total_available_quantity
              : item.order_weight;
          o.selected = item.selected;
        }
      });
      const items = [
        {
          id: item.id,
          orderQuantity: !item.is_order_by_weight
            ? item.order_qty
            : item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
            ? Number(item.total_available_quantity)
            : Number(item.order_weight),
          orderWeight:
            item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
              ? item.total_available_quantity
              : item.order_weight,
          selected: item.selected
        }
      ];
      await this.updateItems(items);
    },

    getAllTenantSelected() {
      const data = [];
      const values = Object.values(this.groupedCartByTenant);

      Object.keys(this.groupedCartByTenant).map((tenantId, index) => {
        const totalSelected = values[index].reduce(
          (total, current) => (current.selected ? total + 1 : total),
          0
        );
        const total = values[index].reduce((total, current) => {
          if (current.is_order_by_weight) {
            return current.order_weight > 0 ? total + 1 : total;
          } else if (this.isItemDisable(current)) {
            current.selected = false;
            return total;
          } else {
            return total + 1;
          }
        }, 0);

        data.push({
          tenantId: parseInt(tenantId),
          total,
          totalSelected
        });
      });
      this.allTenantSelected = data;
    },
    getGroupedCartByTenant() {
      if (!this.localCart) return null;

      const items = this.localCart.items.map((v) => {
        return this.getItemCart(v);
      });
      const data = _.groupBy(items, (item) => item.tenant_id);
      this.groupedCartByTenant = data;
    },

    //Check latest items/product price
    async getLatestPrice() {
      if (!this.localCart?.items || this.localCart?.items.length === 0) {
        this.latesCartItemPrice = [];
        return;
      }
      this.checkLatestPrice = this.localCart?.items.map(function (cartItem) {
        return cartItem.sku_id;
      });
      try {
        const { data } = await apolloClient.query({
          query: getLatestPrice,
          variables: {
            skuIds: this.checkLatestPrice,
            accountNumber: this.selectedCompany.account_number
          }
        });
        this.latesCartItemPrice = data.getLatestPrice;
      } catch (error) {
        this.latestPriceError = true;
        if (this.$route.path === '/b2b/cart') {
          this.showAlertError(error.message);
        }
      }
    },

    //Compare the current price with the lates price and auto update cart items
    async compareCartItemPrice() {
      if (this.latesCartItemPrice && this.latesCartItemPrice.length) {
        this.currentCartItemsPrice = this.localCart?.items.map(function (cartItem) {
          return cartItem.price;
        });
        this.latestPrice = this.latesCartItemPrice.map(function (cartItem) {
          return cartItem.price;
        });
        const cartItemsId = this.localCart?.items.map(function (cartItem) {
          return cartItem.id;
        });
        let cartItemUpdatePrice = cartItemsId?.map((cartItemsId, index_value) => {
          return {
            cartItemId: cartItemsId,
            price: this.latestPrice[index_value]
          };
        });

        if (JSON.stringify(this.currentCartItemsPrice) !== JSON.stringify(this.latestPrice)) {
          await this[ACTIONS_UPDATE_PRICE.UPDATE_LATEST_PRICE]({
            payload: cartItemUpdatePrice
          });
          this.updateCartToStore();
        }
      }
    },

    getItemCart(v) {
      const totalPrice = Number(
        displayPrice(
          v.is_order_by_weight
            ? Number((v.price / v.weight) * Number(v.order_weight))
            : v.order_qty * Number(v.price)
        )
      );
      let selected = v.selected;
      if (selected) {
        if (v.is_order_by_weight) {
          selected = v.order_weight > 0;
        } else if (v.max_quantity !== -1) {
          selected =
            v.order_qty <= v.max_quantity ||
            !v.is_out_of_stock ||
            v.total_available_quantity > 0 ||
            !v.is_order_by_weight;
        }
      }
      return {
        ...v,
        quantity: v.order_qty,
        unit_price: Number(v.price),
        total_price: v.is_sample ? 0 : totalPrice,
        price: v.is_sample ? 0 : v.price,
        tax: Number(displayPrice((Number(v.tax_rate) / 100) * totalPrice)),
        available: true,
        selected
      };
    },

    async uncheckCheckboxItem(item) {
      this.localCart.items.forEach((o) => {
        if (o.id === item.id) {
          o.order_qty = !item.is_order_by_weight
            ? item.order_qty
            : item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
            ? item.total_available_quantity
            : item.order_weight;
          o.order_weight =
            item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
              ? item.total_available_quantity
              : item.order_weight;
          o.selected = !item.selected;
        }
      });
      const items = [
        {
          id: item.id,
          orderQuantity: !item.is_order_by_weight
            ? item.order_qty
            : item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
            ? Number(item.total_available_quantity)
            : Number(item.order_weight),
          orderWeight:
            item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
              ? item.total_available_quantity
              : item.order_weight,
          selected: !item.selected
        }
      ];
      await this.updateItems(items);
    },

    // toggle checkbox item
    async toggleCheckboxItem(item) {
      this.localCart.items.forEach((o) => {
        if (o.id === item.id) {
          o.order_qty = !item.is_order_by_weight
            ? item.order_qty
            : item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
            ? item.total_available_quantity
            : item.order_weight;
          o.order_weight =
            item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
              ? item.total_available_quantity
              : item.order_weight;
          o.selected = !item.selected;
        }
      });
      const items = [
        {
          id: item.id,
          orderQuantity: !item.is_order_by_weight
            ? item.order_qty
            : item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
            ? Number(item.total_available_quantity)
            : Number(item.order_weight),
          orderWeight:
            item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
              ? item.total_available_quantity
              : item.order_weight,
          selected: !item.selected
        }
      ];
      await this.updateItems(items);
    },

    // toggle checkbox all
    toggleCheckboxAllByTenant(tenantId) {
      if (!this.disableCheckboxAll) {
        const index = this.allTenantSelected.findIndex((item) => item.tenantId === tenantId);
        if (index === -1) return;

        const currentTenant = this.allTenantSelected[index];

        // get all items to update
        const items = this.getListItemsByTenant(
          tenantId,
          currentTenant.totalSelected !== currentTenant.total
        );
        this.updateItems(items);
      }
    },

    async updateItems(items) {
      this.getGroupedCartByTenant();
      this.getAllTenantSelected();

      await this[ACTIONS_CART.CHANGE_CART_ITEMS]({
        customerId: this.selectedCompany.id,
        items
      });
    },

    async refetchCartItems() {
      await this.getGroupedCartByTenant();
      await this.getAllTenantSelected();
      await this.getLatestPrice();
      await this.compareCartItemPrice();
      this.selectedCompany = await this.$storage.getSelectedCompany();
      setBadgeNumber(this.store, this.selectedCompany.id);
      return;
    },

    closeNoAvailableItems() {
      this.setOpenItemsNoAvailable(false);
      this.updateCartToStore();
    },

    getListItemsByTenant(tenantId, selected) {
      // update local item
      this.localCart.items.forEach((item) => {
        const itemInTenant = this.groupedCartByTenant[tenantId].filter((o) => o.id === item.id);
        if (itemInTenant) {
          item.selected = this.isItemDisable(item) ? false : selected;
        }
      });
      const items = this.groupedCartByTenant[tenantId].map((item) => {
        return {
          id: item.id,
          orderQuantity: !item.is_order_by_weight
            ? item.order_qty
            : item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
            ? parseInt(item.total_available_quantity)
            : parseInt(item.order_weight),
          orderWeight:
            item.total_available_quantity > 0 && item.order_weight > item.total_available_quantity
              ? item.total_available_quantity
              : item.order_weight,
          selected: this.isItemDisable(item) ? false : selected
        };
      });
      return items;
    },
    async refreshCartItems() {
      const prevLocalSelectedItems = this.itemsSelected[0]?.map((item) => item);

      await this[ACTIONS_CART.GET_CART_ITEMS]({
        userId: this.user.id,
        customerId: this.selectedCompany.id
      });

      //check if the selected cart item oos status have update and then compare it with lates data update
      this.latesCartItem = [];
      const localCartItems = this.localCart.items;
      let itemSelectedData = this.itemsSelected[0]?.map((item) => item);

      for (let i = 0; i < localCartItems.length; i++) {
        for (let j = 0; j < itemSelectedData.length; j++) {
          if (localCartItems[i].sku_id === itemSelectedData[j].sku_id) {
            this.latesCartItem.push(localCartItems[i].is_out_of_stock);
          }
        }
      }

      // Create a map of latestCartItems for efficient lookup by ID
      const latestCartItemsMap = new Map(localCartItems.map((item) => [item.id, item]));
      // Iterate through prevLocalSelectedItems and compare is_unavailable or order weight > total available quantity
      prevLocalSelectedItems.forEach((prevItem) => {
        const latestItem = latestCartItemsMap.get(prevItem.id);
        const blockedCartItems =
          !latestItem.is_back_order &&
          (latestItem.is_order_by_weight ? latestItem.order_weight : latestItem.order_qty) >
            latestItem.total_available_quantity;
        if (blockedCartItems) {
          // set order weight and order quantity same with total available quantity
          this.itemsUpdate = {
            ...latestItem,
            order_weight: latestItem.total_available_quantity
              ? Number(latestItem.total_available_quantity?.toFixed(2))
              : latestItem.order_weight,
            order_qty: latestItem.total_available_quantity
              ? Number(latestItem.total_available_quantity)
              : latestItem.order_qty
          };
          this.updateQuantityWeightItem(this.itemsUpdate);
        }
      });
    }
  }
});
</script>
<style lang="scss" scoped>
.cart-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 88vh;
  position: fixed;
}

.cart-empty-img {
  height: 128px;
  width: 128px;
}

.text-capitalize {
  text-transform: capitalize;
}

.msg-empty {
  font-size: 12px;
}

.title-tenant {
  max-width: 80%;
}

.block-tenant-list {
  background-color: #eceff1;
}

.block-tenant-item {
  background-color: white;
}

ion-content::part(background) {
  --background: #eceff1;
}
.disabled-checkbox {
  pointer-events: none;
}
</style>

<style lang="scss">
.block-tenant-list .item-checkbox {
  font-size: 22px;
}

.modal-delete {
  --width: calc(100% - 3rem);
  --height: 30%;
}

.modal-delete .modal-wrapper {
  border-radius: 12px;
}

.modal-update-price {
  --width: 80%;
  --height: 30%;
}
.border-rad-0 {
  border-radius: 0 !important;
}
.column-center {
  flex-direction: column;
  align-items: center;
}
.fs-2rem {
  font-size: 2rem;
}
</style>
