<template>
  <ion-button @click="goToNotifications" size="small" fill="clear" class="btn-notify">
    <ion-icon slot="icon-only" :icon="notificationsOutline" class="header-icon"></ion-icon>
    <div class="notify-count" v-show="numberBadge > 0">
      <div class="notify-badge" :class="{ number: numberBadge <= 9 }">
        {{ numberBadge > 9 ? '9+' : numberBadge }}
      </div>
    </div>
  </ion-button>
</template>

<script>
import { getNotificationBadge } from '@/modules/shared/utils/badge';
import { notificationsOutline } from 'ionicons/icons';
import { computed, defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'NotificationBadge',
  setup() {
    const store = useStore();
    const isOpenLoadingref = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const numberBadge = computed(() => getNotificationBadge(store));
    const router = useRouter();
    const isMerchantUser = router.currentRoute.value.fullPath.indexOf('/b2b') > -1;
    const rootPath = isMerchantUser ? 'b2b' : 'sale';
    const goToNotifications = () => {
      router.push(`/${rootPath}/notifications/`);
    };

    return {
      notificationsOutline,
      setOpenLoading,
      isOpenLoadingref,
      numberBadge,
      goToNotifications
    };
  }
});
</script>

<style scoped lang="scss">
.header-icon {
  color: var(--ion-color-primary);
}

.btn-notify {
  position: relative;
  width: 32px;
  height: 32px;
}
.btn-notify::part(native) {
  padding: 0px;
}

.notify-count {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  position: absolute;
  background: #f44336;
}
.notify-badge {
  position: absolute;
  font-size: 8px;
  color: white;
  text-align: center;
  top: 4px;
  left: 3px;
  &.number {
    left: 5px;
  }
}
</style>
