<template>
  <div class="cnt-modal-price px-3">
    <ion-icon class="info mt-3" :icon="informationCircleOutline" />
    <div>
      <ion-label class="fw-500 fs-3">{{ $t('price_changes') }}</ion-label>
    </div>
    <div class="my-3">
      <ion-label>{{ $t('please_try_again') }}</ion-label>
    </div>
    <ion-button expand="full" fill="clear" color="primary" @click="$emit('close-modal')">{{
      $t('OK')
    }}</ion-button>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import { informationCircleOutline } from 'ionicons/icons';

export default defineComponent({
  setup() {
    return {
      informationCircleOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.info {
  height: 32px;
  width: 32px;
  color: #00676a;
  margin-bottom: 16px;
}
.cnt-modal-price {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}
</style>
