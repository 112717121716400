<template>
  <div class="top-content">
    <ion-grid @click="setOpenCompanySelection(true)">
      <transition name="slide-fade" :duration="{ enter: 10000 }">
        <ion-row
          class="ion-align-items-center ion-justify-content-between"
          style="margin-bottom: -7px"
          v-if="isHideSome"
        >
          <ion-col size="auto">
            <ion-label class="fs-1"> {{ $t('your_company') }} </ion-label>
          </ion-col>
          <ion-col size="auto" v-if="isEnabledChangeCompany()">
            <div class="d-flex align-items-center">
              <ion-label class="fs-1 fw-500" color="primary">{{ $t('change') }}</ion-label>
              <ion-icon
                size="small"
                color="primary"
                v-if="!isOpenCompanySelection"
                :icon="chevronDownOutline"
              ></ion-icon>
              <ion-icon size="small" color="primary" v-else :icon="chevronUpOutline"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </transition>

      <ion-row class="ion-align-items-center ion-justify-content-between" style="margin-bottom: -5px">
        <ion-col size="10">
          <ion-text class="fw-500 fs-2 two-line-text"
            >{{ selectedCompany?.name || selectedCompany?.branch }}
          </ion-text>
        </ion-col>
        <transition name="slide-fade" :duration="{ enter: 10000 }">
          <ion-col size="auto" v-if="!isHideSome">
            <ion-icon
              size="small"
              color="primary"
              v-if="!isOpenCompanySelection"
              :icon="chevronDownOutline"
            ></ion-icon>
            <ion-icon size="small" color="primary" v-else :icon="chevronUpOutline"></ion-icon>
          </ion-col>
        </transition>
      </ion-row>
      <transition name="fade" :duration="{ enter: 10000 }">
        <ion-row class="ion-align-items-center" style="margin-bottom: -5px" v-if="isHideSome">
          <ion-col size="auto" style="margin-right: -5px">
            <div class="text-gray-700 fw-500">{{ selectedCompany?.account_number ?? '-' }}</div>
          </ion-col>
          <ion-col
            v-if="!isMerchantUser && selectedCompany?.black_list"
            size="auto"
            style="margin-right: -5px"
          >
            <div class="box-label d-flex align-center">
              <ion-icon class="fs-2" :icon="banOutline" color="danger"></ion-icon>
              <span class="text-danger ml-1 fs-1 fw-500">{{ $t('black_listed') }}</span>
            </div>
          </ion-col>
          <ion-col v-if="!isMerchantUser && selectedCompany?.has_credit_block" size="auto">
            <div class="box-label d-flex align-center credit-block">
              <span class="text-white fs-1">{{ $t('credit_block') }}</span>
            </div>
          </ion-col>
        </ion-row>
      </transition>

      <ion-row class="ion-align-items-center ion-justify-content-between">
        <ion-col size="10">
          <div class="d-flex align-items-center">
            <ion-icon size="small" :icon="'/assets/images/location-outline.svg'" />
            <ion-text class="fs-1 pl-1 one-line-text">
              {{ displayAddress() }}
            </ion-text>
          </div>
        </ion-col>
        <ion-col size="auto" v-if="addresses.length > 1">
          <div class="d-flex align-items-center">
            <ion-label class="fs-1 fw-500" color="primary">
              {{ `+${addresses.length - 1} ${$t('more')}` }}
            </ion-label>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-modal
      ref="modal"
      :initial-breakpoint="1"
      :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
      mode="ios"
      css-class="default-bottom-sheet-modal"
      :is-open="isOpenCompanySelection"
      @didDismiss="setOpenCompanySelection(false)"
    >
      <modal-branch-selection
        v-if="!isMerchantUser"
        @refreshData="$emit('refreshData')"
        @closeModal="setOpenCompanySelection(false)"
      />
      <modal-branch-selection-b2b
        v-else
        @refreshData="$emit('refreshData')"
        @closeModal="setOpenCompanySelection(false)"
      />
    </ion-modal>
  </div>
</template>
<script>
import ModalBranchSelectionB2b from '@/modules/b2b/views/customer/BranchSelection.vue';
import ModalBranchSelection from '@/modules/sale/views/customer/BranchSelection.vue';
import { useCardChangeCompany } from '@/usecases/customer';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'CardChangeCompany',
  emits: ['openCompanySelection', 'refreshData'],
  components: {
    ModalBranchSelection,
    ModalBranchSelectionB2b
  },
  props: {
    selectedCompany: {
      type: Object,
      default: () => ({})
    },
    isHideSome: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      displayAddress,
      addresses,
      isMerchantUser,
      isOpenCompanySelection,
      setOpenCompanySelection,
      chevronDownOutline,
      banOutline,
      chevronUpOutline,
      isEnabledChangeCompany
    } = useCardChangeCompany(props);

    return {
      displayAddress,
      isEnabledChangeCompany,
      addresses,
      isMerchantUser,
      isOpenCompanySelection,
      setOpenCompanySelection,
      chevronDownOutline,
      banOutline,
      chevronUpOutline
    };
  }
});
</script>
<style lang="scss" scoped>
.top-content {
  margin-bottom: -0.6rem;
}
.image-default {
  width: 36px;
  height: 36px;
  border-radius: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-card {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--ion-color-text-primary-green-600);
  font-weight: 700;
}
.box-label {
  background-color: var(--ion-color-text-error-100);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 4px;
  &.credit-block {
    background-color: #f15c51;
  }
}
.two-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
}
.one-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(-100px);
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 0.4s ease-in;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
